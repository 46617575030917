/* Overall page background */
.payment-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 50px;
  background-color: rgba(0, 0, 50, 0.05); /* Light transparent blue-grey background */
}

/* Payment container styles */
.payment-container {
  max-width: 900px; /* Wider width for the container */
  margin: 30px auto;
  padding: 40px; /* Adjust padding for a consistent look */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Header styles */
.payment-container h1 {
  font-size: 75px;
  margin-bottom: 15px;
  color: #004d26;
}

/* Paragraph styles */
.payment-container p {
  margin-bottom: 15px;
  font-size: 18px;
  color: #666;
}

/* Search method buttons */
.search-method {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.search-method button {
  padding: 12px 20px;
  margin: 0 10px;
  background-color: #004d26;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px; /* Larger font size for consistency */
  transition: background-color 0.3s;
}

.search-method button.active {
  background-color: #00371d;
}

.search-method button:hover {
  background-color: #00371d;
}

/* Ticket check form styles */
.ticket-check-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto 15px;
  text-align: center;
}

.ticket-check-form label {
  font-size: 18px;
  margin-bottom: 8px;
  width: 100%;
  font-weight: bold;
}

.ticket-check-form input {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 500px; /* Match the max width to the overall container */
  margin-bottom: 15px;
  text-align: center;
}

/* Button styles */
.ticket-check-form button {
  padding: 12px 20px;
  background-color: #004d26;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 500px;
}

.ticket-check-form button:hover {
  background-color: #00371d;
}

/* Invoice and payment details */
.invoice-container,
.payment-details {
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 20px;
}

.invoice-container h2 {
  font-size: 36px;
  color: #004d26;
  margin-bottom: 20px;
}

.invoice-details p {
  font-size: 16px;
  color: #333;
  margin: 10px 0;
}

.invoice-error {
  color: #d9534f;
  font-size: 16px;
  margin-top: 10px;
}

.no-invoice {
  font-size: 16px;
  color: #d9534f;
}

/* Payment details section */
.payment-details h3 {
  font-size: 24px;
  color: #004d26;
  margin-bottom: 15px;
}

.payment-details .form-group {
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}

.payment-details .form-group label {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.payment-details .form-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Stripe container */
.stripe-container {
  margin-top: 20px;
}

/* Download button */
.download-btn {
  background-color: #004d26;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: 10px 0;
  transition: background-color 0.3s;
}

.download-btn:hover {
  background-color: #00371d;
}

/* Responsive adjustments for mobile devices */
@media (max-width: 768px) {
  .payment-container {
    max-width: 95%;
    padding: 20px;
  }

  .search-method button,
  .ticket-check-form button {
    font-size: 14px;
    padding: 10px 15px;
  }

  .ticket-check-form input {
    font-size: 14px;
    padding: 10px;
  }

  .payment-container h1 {
    font-size: 35px;
    margin-bottom: 15px;
  }

  .invoice-container,
  .payment-details {
    width: 100%;
    margin-bottom: 10px;
  }

  .download-btn {
    font-size: 14px;
    padding: 8px 15px;
  }
}

.total-amount {
  font-size: 20px;
  color: #004d26;
  font-weight: bold;
  margin-top: 15px;
}

.download-invoice {
  margin-top: 20px;
}

.download-link {
  text-decoration: none;
  color: white;
  background-color: #004d26;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.download-link:hover {
  background-color: #00371d;
}

.billing-details {
  margin-top: 20px;
  margin-bottom: 20px;
}

.billing-details .form-group {
  margin-bottom: 10px;
}

.stripe-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
}

.stripe-container h3 {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}


.download-btn {
  margin-top: 20px;
  background-color: #004d26;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-btn:hover {
  background-color: #003d1e;
}
