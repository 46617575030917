.reviews-container {
  text-align: center;
  padding: 50px;
}

.reviews-title {
  text-align: center;
  font-size: 42px;
  font-weight: bold;
  color: #004d26;
  margin-top:20px;
  margin-bottom: 25px;
  letter-spacing: 1.5px;
}
  
  
  .approved-reviews {
    margin-bottom: 40px;
  }
  
  .review-form {
    margin-bottom: 20px;
  }
  
  .star-rating {
    font-size: 24px;
  }
  
  .star {
    cursor: pointer;
  }
  
  .star.filled {
    color: gold;
  }
  
  .review-form textarea,
  .review-form input {
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    resize: none;
  }
  
  .review-form button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #004d26;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .review-form button:hover {
    color : white;
  }
  
  .reviews-list {
    margin-top: 20px;
  }
  
  .review {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .review-name {
    font-weight: bold;
    color: #004d26; /* Customize to match your theme */
    margin-top: 5px;
  }
  