/* Basic styling for the navbar */
.navbar {
  background: #f5deb3;
  height: 75px; /* Increased height for the navbar */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem; /* Slightly larger text size */
}

.navbar-container {
  display: flex;
  justify-content: space-between; /* Align elements across the navbar */
  align-items: center;
  height: 100%; /* Match navbar height */
  z-index: 1;
  width: 100%;
  max-width: 1500px;
  padding: 0 50px;
  position: relative;
}

.navbar-logo {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.navbar-logo-img {
  height: 120px; /* Larger logo size */
  width: auto; /* Maintain aspect ratio */
}

/* Mobile menu icon */
.menu-icon {
  color: #004d26;
  display: none;
  position: absolute;
  right: 0;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  gap: 20px; /* Add spacing between menu items */
}

.nav-menu.active {
  background: #fff;
  position: absolute;
  top: 100px; /* Matches the navbar height */
  left: 0;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; /* Ensures equal spacing between items */
  padding-bottom: 20px; /* Add padding at the bottom */
  transition: all 0.5s ease;
  opacity: 1;
  z-index: 1;
}

.nav-item {
  height: auto; /* Adjusted to auto for better alignment */
}

.nav-links {
  color: #004d26;
  text-decoration: none;
  padding: 0.5rem 1.5rem; /* Add padding for better spacing */
  width: auto;
  text-align: center; /* Center-align text */
  font-size: 1.2rem; /* Larger font for menu items */
  transition: all 0.3s ease;
}

.nav-links:hover {
  border-bottom: 2px solid #004d26; /* Add an underline effect */
}

.nav-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn--outline {
  border: 1px solid #004d26;
  color: #004d26;
  background-color: #fff;
  padding: 12px 24px; /* Larger button size */
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.2rem; /* Larger font for buttons */
}

.btn--outline:hover {
  background-color: #004d26;
  color: #fff;
}

.btn--primary {
  background-color: #004d26;
  color: #fff;
  padding: 12px 24px; /* Larger button size */
  font-size: 1.2rem; /* Larger font for buttons */
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn--primary:hover {
  background-color: #fff;
  color: #004d26;
}

/* Responsive Design */
@media screen and (max-width: 960px) {
  .navbar-container {
    padding: 0;
  }

  .navbar-logo-img {
    height: 100px; /* Adjust logo size for smaller screens */
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 30px; /* Vertically align with navbar */
    right: 20px;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120vh;
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #fff; /* Ensure the white background is applied */
    position: absolute;
    top: 100px; /* Matches the navbar height */
    left: 0;
    width: 100%;
    height: auto; /* Allow the height to adapt dynamically */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align items from the top */
    padding-top: 20px; /* Add padding at the top */
    z-index: 1; /* Ensure it appears above other elements */
  }
  

  .nav-links {
    text-align: center;
    padding: 1.5rem 0; /* Adjust spacing for better alignment */
    width: 100%;
    display: block;
  }

  .nav-links:hover {
    background-color: #f5deb3;
    color: #004d26;
  }

  .nav-item {
    width: 100%;
  }

  .btn--outline,
  .btn--primary {
    display: none; /* Hide buttons in the navbar on mobile */
  }
}

.nav-item.mobile-only {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-item.mobile-only {
    display: block;
    text-align: center;
    margin-top: 10px;
  }
}
