/* Center the main container */
.contactme, .payment-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding: 50px;
  background-color: #f4f4f4;
}

/* Contact form and payment form styles */
.contact-form, .payment-container {
  background-color: 666;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 800px; /* Increased max width to make it wider */
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Header styles for both forms */
.contactme-header, .payment-container h1 {
  text-align: center;
  font-size: 75px;
  margin-bottom: 20px;
  color: #004d26;
}

/* Common paragraph style */
.payment-container p, .contactme p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #666;
}

/* Form group for labels and inputs */
.form-group, .ticket-check-form {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group label, .ticket-check-form label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  width: 100%;
}

.form-group input,
.form-group textarea,
.form-group select,
.ticket-check-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  max-width: 600px; /* Increase max width of inputs to match container width */
  margin-bottom: 15px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

/* Button styles for both forms */
button[type='submit'], .ticket-check-form button, .search-method button {
  padding: 12px 20px;
  background-color: #004d26;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-top: 10px;
}

button[type='submit']:hover, .ticket-check-form button:hover, .search-method button:hover {
  background-color: #00371d;
}

/* Search method buttons */
.search-method {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-method button {
  margin: 0 10px;
}

.search-method button.active {
  background-color: #00371d;
}

/* Invoice container */
.invoice-container {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

/* Payment details */
.payment-details {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.payment-details .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.payment-details .form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.payment-details .form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Status message and ticket number */
.status-container {
  margin-top: 20px;
  text-align: center;
}

.submit-status,
.ticket-number {
  font-size: 18px;
  font-weight: bold;
  color: green;
}

/* Error messages */
.invoice-error {
  color: #d9534f;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}

/* Responsive Design for mobile devices */
@media (max-width: 600px) {
  .contact-form, .payment-container {
    padding: 20px;
    max-width: 100%;
  }

  button[type='submit'], .ticket-check-form button, .search-method button {
    font-size: 14px;
    padding: 10px 15px;
  }

  .form-group input,
  .form-group textarea,
  .form-group select,
  .ticket-check-form input {
    font-size: 14px;
    padding: 8px;
  }

  .contactme-header, .payment-container h1 {
    font-size: 35px;
    margin-bottom: 15px;
  }

  .submit-status,
  .ticket-number {
    font-size: 16px;
  }
}
