/* General Guidebook Header */
.guidebook-header {
  text-align: center;
  font-size: 42px;
  font-weight: bold;
  color: #004d26;
  margin-top: 20px;
  margin-bottom: 25px;
  letter-spacing: 1.5px;
}

/* Iframe Container */
.iframe-container {
  width: 100%;
  height: 950px; /* Default height */
  overflow: hidden;
  margin: 0 auto;
  max-width: 1500px; /* Restrict for readability */
  position: relative;
}

/* Embedded iframe */
iframe {
  width: 100%;
  height: 100%;
  border: none; /* Clean, no border */
}

/* Fallback Text for Unsupported Devices */
.fallback-text {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  color: #777;
}

.fallback-text a {
  color: #004d26;
  text-decoration: underline;
  font-weight: bold;
}

.fallback-text a:hover {
  color: wheat;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .guidebook-header {
    font-size: 36px; /* Smaller font size for mobile */
    margin-top: 10px; /* Adjust spacing */
    margin-bottom: 20px;
    letter-spacing: 1px;
  }

  .iframe-container {
    height: 500px; /* Reduced height for smaller screens */
    max-width: 100%; /* Ensure it uses full mobile width */
    margin-top: 10px; /* Adjust margin spacing */
  }

  iframe {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .guidebook-header {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .iframe-container {
    height: 400px; /* Compact height for very small devices */
    margin-top: 10px;
  }

  iframe {
    height: 100%;
    width: 100%;
  }

  .fallback-text {
    font-size: 12px;
  }
}
