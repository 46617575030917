/* Hero Section */
.hero-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  z-index: -1;
  background-color: #F5F5F5;
  padding: 0;
}

/* Flowing Waves Container */
.flowing-waves {
  position: absolute;
  bottom: -30px; /* Push waves down slightly */
  left: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  z-index: -1; /* Send the waves to the background */
}

/* Animating the Waves */
@keyframes waveFlow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.wave-svg {
  width: 200%;
  height: auto;
  animation: waveFlow 15s linear infinite;
}

/* Overlay content */
.overlay-content {
  position: relative;
  text-align: center;
  z-index: 1;
  color: #004d26;
  transform: translateY(-20px); /* Center above the wave */
}

/* Title Styling */
.home-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 10px;
  animation: floating 3s ease-in-out infinite;
}

.home-title-green {
  color: #004d26;
}

.home-title-wheat {
  color: wheat;
}

/* Tagline Styling */
.home-tagline {
  font-size: 1.2rem;
  margin-top: 5px;
}

/* Button Styles */
.home-buttons {
  display: flex;
  flex-direction: row; /* Horizontal layout */
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}

.btns {
  background-color: #004d26;
  color: #fff;
  font-size: 1rem;
  padding: 10px 15px;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
  text-align: center;
  flex: 1;
  max-width: 140px; /* Restrict button width */
}

.btns:hover {
  background-color: wheat;
  color: #004d26;
}

/* Floating Animation */
@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Global Reset */
body, html {
  margin: 0;
  padding: 0;
  background: none;
}






/* Media Queries for iPhone (480px and below) */
@media (max-width: 480px) {

  .overlay-content {
    transform: translateY(-30px); /* Center better */
  }

  .home-title-green {
    font-size: 3rem;
  }

  .home-title-wheat {
    font-size: 3rem;
  }

  .home-tagline {
    font-size: 16rwm;
  }

  /* Updated Button Layout for Horizontal Alignment */
  .home-buttons {
    display: flex;
    flex-direction: row; /* Buttons side by side */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Align vertically */
    gap: 8px; /* Space between buttons */
    margin-top: 20px;
  }

  .btns {
    flex: 1; /* Allow buttons to scale equally */
    max-width: 120px; /* Restrict button width */
    padding: 8px 12px; /* Adjust button size */
    font-size:50px;
  }

  .flowing-waves {
    bottom: -30px; /* Push SVG wave further down */
  }
/* Floating Animation */
@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}
}
