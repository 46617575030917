/* General Container */
.services-container {
  width: 100%;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Title */
.services-title {
  text-align: center;
  font-size: 42px;
  font-weight: bold;
  color: #004d26;
  margin-top:20px;
  margin-bottom: 25px;
  letter-spacing: 1.5px;
}
  

.services-title2 {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #004d26;
  margin-bottom: 40px;
  letter-spacing: 1.5px;
}


/* Stats Section */
.stats-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 40px;
  background: linear-gradient(to right, #f0f4f8, #ffffff);
  border-radius: 15px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 1200px;
}

.stats-title {
  font-size: 60px;
  margin-top: 60px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #004d26;
  text-align: center;
  width: 100%;
}

.stats-content {
  display: flex;
  justify-content: space-around;
  gap: 25px;
  width: 100%;
}

.stat {
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.stat h3 {
  font-size: 40px;
  color: #008060;
  margin-bottom: 10px;
}

.stat p {
  color: black;
  font-size: 16px;
}

/* Main Description Section */
.description-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 40px 20px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 1200px;
}

.description-content {
  flex: 2;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.services-image {
  width: 300px;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.text-content {
  max-width: 800px;
  margin-top: 30px;
  text-align: center;
}

.computers-laptops-title {
  font-size: 40px;
  font-weight: bold;
  color: #004d26;
  margin-bottom: 10px;
}

.description-text {
  font-size: 22px;
  line-height: 1.6;
  color: #333;
}

/* Service Dropdown */
.services-dropdown {
  flex: 1;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 1200px;
}

.service-item {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
  background-color: wheat;
  width: 100%;
}

.service-title {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #004d26;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-title span {
  font-size: 18px;
  color: #008060;
}

.service-details {
  padding: 10px 20px;
  background-color: #ffffff;
  font-size: 16px;
  color: #333333;
  overflow: hidden;
  box-sizing: border-box;
  max-width: 100%;
}

.contact-btn {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  background: #004d26;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 10px;
}

.contact-btn:hover {
  background: #006d36;
}

/* Video Section */
.video-section {
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 40px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  width: 100%;
  background-color: #000;
}

.services-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .stats-section {
    flex-direction: column;
    align-items: center;
  }

  .stats-content {
    flex-direction: column;
    gap: 10px;
  }

  .description-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px 20px;
    margin-bottom: 40px;
  }

  .services-image {
    width: 100%; /* Full-width for mobile */
    height: auto;
    margin-bottom: 20px;
    align-self: center; /* Center the image */
  }

  .computers-laptops-title {
    font-size: 30px; /* Slightly smaller for tablets */
    text-align: center; /* Center align the title */
    margin: 15px 0; /* Add spacing around the title */
  }

  .description-text {
    font-size: 20px; /* Adjust font size */
    line-height: 1.5; /* Slightly increase line height */
    text-align: center; /* Center align the text */
    max-width: 100%; /* Full-width for mobile */
    margin-bottom: 10px; /* Add spacing below text */
  }

  .services-dropdown {
    max-width: 100%; /* Full-width dropdown for smaller screens */
  }

  .stats-title {
    font-size: 32px;
    margin-top: 60px;
    font-weight: bold;
    margin-bottom: 0px;
    color: #004d26;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .stats-section {
    padding: 15px; /* Reduce padding for smaller devices */
  }

  .description-section {
    padding: 15px; /* Reduce padding for phones */
  }

  .services-image {
    width: 100%; /* Full-width for mobile */
    height: auto;
  }

  .computers-laptops-title {
    font-size: 28px; /* Slightly smaller for phones */
    margin: 10px 0; /* Adjust spacing */
    text-align: center; /* Ensure it is centered */
  }

  .description-text {
    font-size: 16px; /* Smaller font for mobile */
    line-height: 1.4; /* Adjust line height */
    text-align: center; /* Center align the text */
  }

  .stats-title {
    font-size: 30px;
    margin-top: 60px;
    font-weight: bold;
    margin-bottom: 0px;
    color: #004d26;
    text-align: center;
    width: 100%;
  }
}
