/* General Container */
.aboutme-container {
  text-align: center;
  padding: 20px;
  background-color: #fdfdfd;
}

.aboutme-header {
  text-align: center;
  font-size: 42px;
  font-weight: bold;
  color: #004d26;
  margin-top:20px;
  margin-bottom: 25px;
  letter-spacing: 1.5px;
}
  
  

/* About Me Section */
.aboutme-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  color:#555;
  font-size: 18px;
}

.my-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.aboutme-info {
  max-width: 600px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.timeline-title {
  font-size: 32px;
  color: #004d26;
  margin-bottom: 20px;
}

.title {
  color:#004d26;
  font-size: 36px;

}

/* Carousel */
.carousel-container {
  margin: 0 auto;
  max-width: 800px;
}

.timeline-box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.timeline-image {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  margin-bottom: 15px;
  border-radius: 10px;
}

.timeline-box h3 {
  font-size: 24px;
  color: #004d26;
}

.timeline-date,
.timeline-details {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

/* Pagination Buttons */
.carousel-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-btn {
  background-color: #004d26;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.pagination-btn.active,
.pagination-btn:hover {
  background-color: wheat;
  color: #004d26;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .aboutme-content {
    flex-direction: column;
    text-align: center;
  }

  .timeline-box {
    padding: 10px;
  }

  .timeline-image {
    max-height: 200px;
  }

  .pagination-btn {
    font-size: 14px;
    padding: 8px 12px;
  }
}

.carousel-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-btn {
  background-color: #004d26;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.pagination-btn.active,
.pagination-btn:hover {
  background-color: wheat;
  color: #004d26;
}
