/* General Container */
.computers-container {
  padding: 20px;
  text-align: center;
  background-color: #fdfdfd;
}

/* Title */
.computers-title {
  text-align: center;
  font-size: 42px;
  font-weight: bold;
  color: #004d26;
  margin-top:20px;
  margin-bottom: 25px;
  letter-spacing: 1.5px;
}
  

/* Cards Container */
.computer-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Individual Card */
.computer-card {
  width: 300px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.computer-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

/* Card Image */
.computer-image {
  width: 90%;
  height: 180px;
  object-fit: cover;
}

/* Card Title */
.computer-card h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 10px 0;
}

/* Saved Price Tag */
.sold-price {
  background-color: #004d26;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 10px;
}

/* FPS Details */
.fps-details {
  margin-top: 10px;
  font-size: 14px;
  color: #444;
}

.fps-details .spec-label {
  font-weight: bold;
  color: #555;
  text-transform: uppercase;
}


/* Description */
.computer-description {
  color: #777;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 0 10px;
}

/* Expanded Content */
.expanded-content {
  background-color: #f9f9f9;
  padding: 15px;
  border-top: 1px solid #ddd;
  text-align: left;
  font-size: 14px;
  line-height: 1.6;
}

.expanded-content p {
  margin: 5px 0;
  color: #444;
}

.spec-label {
  font-weight: bold;
  text-transform: uppercase;
  color: #555;
}

.sold-price-modal {
  color: #004d26;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
}

/* Animation for Expanded Content */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scaleY(0);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

.expanded-content {
  animation: fadeIn 0.3s ease-out;
  transform-origin: top;
}

/* Responsive Design */
@media (max-width: 768px) {
  .computer-cards {
    flex-direction: column;
    align-items: center;
  }

  .computer-card {
    width: 90%;
    max-width: 400px;
  }

  .expanded-content {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  
  .computer-card {
    width: 95%;
  }

  .computer-description {
    font-size: 12px;
  }

  .expanded-content {
    font-size: 12px;
  }

  .sold-price {
    font-size: 12px;
    padding: 4px 8px;
  }
}
