.cards {
  padding: 2rem 0;
  background: #f5f5f5;
  overflow: hidden; /* Ensures the carousel doesn't overflow */
  position: relative;
}

.cards__header {
  background-color: wheat;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cards__header h1 {
  color: #004d26;
  font-size: 36px;
  margin: 0;
  font-weight: bold;
}

.cards__container {
  display: flex;
  align-items: center;
  overflow: hidden; /* Hide items outside the viewport */
  position: relative;
}

.cards__track {
  display: flex;
  gap: 20px;
  animation: slide-carousel 35s linear infinite; /* Smooth infinite scrolling */
}

@keyframes slide-carousel {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.cards__item {
  flex: 0 0 calc(100% / 3 - 20px); /* 3 cards visible */
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.cards__item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.cards__item__link {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%; /* Aspect ratio */
  overflow: hidden;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 700;
  color: #004d26;
  background-color: #fff;
  border-radius: 4px;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.15ms ease;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 15px;
  text-align: center;
}

.cards__item__text {
  color: #004d26;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.4;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .cards__item {
    width: calc(33.333% - 20px); /* Three cards per row for tablets */
    margin-top:50px;
  }
}

@media (max-width: 768px) {
  .cards__item {
    width: calc(50% - 20px); /* Two cards per row for smaller devices */
    margin-top:50px;
  }
}

@media (max-width: 480px) {
  .cards__item {
    width: 100%; /* Single card per row for phones */
    margin-top:50px;
  }

  .cards__header h1 {
    font-size: 24px; /* Adjust header font size */
  }

  .cards__item__text {
    font-size: 16px; /* Adjust card text size */
  }

  .cards-section {
    padding: 2rem 0;
    background: #f5f5f5;
    overflow: hidden; /* Ensures the carousel doesn't overflow */
    position: relative;
  }


.cards__container {
  display: flex;
  align-items: center;
  overflow: hidden; /* Hide items outside the viewport */
  position: relative;
}
}
