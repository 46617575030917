/* Global reset and core styling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

body, html {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  overflow-x: hidden;
  scroll-behavior: smooth; /* Smooth scrolling */
}

/* Wave Section Styling */

@keyframes wave-flow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

/* General button styles */
.btns {
  display: inline-block;
  padding: 12px 25px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 8px;
  text-decoration: none;
  color: white;
  background-color: #004d26;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btns:hover {
  background-color: wheat;
  transform: translateY(-3px);
  color: #004d26;
}

/* Home Page Styling */
.home-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f7f7; 
  position: relative; /* Necessary for wave positioning */
  overflow: hidden;
}

.home-title {
  font-size: 5rem;
  font-weight: bold;
  color: #004d26;
  text-align: center;
}

.home-tagline {
  margin-top: 20px;
  font-size: 1.5rem;
  color: #666;
  text-align: center;
}

.home-buttons {
  margin-top: 40px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

