:root {
  --primary: white;
}


.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #004d26;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}


.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: white;
  color: black;
  transition: 250ms;
}

/* Button Styling Update for Mobile */
@media (max-width: 480px) {
  /* Update for the button container */
  .home-buttons {
    display: flex;
    flex-direction: row; /* Align buttons horizontally */
    justify-content: center; /* Center buttons horizontally */
    align-items: center; /* Vertically align buttons */
    gap: 10px; /* Add spacing between buttons */
    margin-top: 20px; /* Add space above the buttons */
  }

  /* Ensure the buttons adjust properly */
  .btn {
    flex: 1; /* Allow buttons to share equal width */
    padding: 10px 20px; /* Adjust button padding */
    font-size: 0.9rem; /* Adjust font size for smaller screens */
    text-align: center; /* Center button text */
    max-width: 150px; /* Restrict maximum button width */
    border-radius: 8px; /* Add rounded corners */
  }

  /* Hover effect for buttons */
  .btn:hover {
    background: #004d26; /* Keep hover behavior */
    color: white; /* Invert text color */
    transition: 250ms ease-in-out;
  }
  

  /* Adjust SVG wave placement to ensure spacing */
  .flowing-waves {
    margin-top: 40px; /* Add spacing above SVG waves */
  }
}
